<template>
  <Layout>
    <PageHeader title="Add / Edit Services" :items="items"> </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="forum">
              <b-form-group>
                <label for="comm" class="mb-2">
                  Add Services <span style="color: red"> *</span>
                </label>
                <multiselect
                  @search-change="fetchServices"
                  id="comm"
                  v-model="form.services"
                  :options="serviceList"
                  :multiple="true"
                  track-by="id"
                  label="name"
                  placeholder="Type here to search"
                  :class="{
                    'is-invalid': submitted && $v.form.services.$invalid,
                  }"
                >
                  <span slot="noOptions"> Type here to search </span>
                </multiselect>
                <div
                  v-if="submitted && !$v.form.services.required"
                  class="invalid-feedback"
                >
                  Service is required.
                </div>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
                >Save Data
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "@/mixins/request";
import clinicMixin from "@/mixins/ModuleJs/services";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mixins: [MixinRequest, clinicMixin],
  data() {
    return {
      submitted: false,
      items: [
        {
          text: "Back",
          href: `/clinic/edit-clinic/${this.$route.params.id}`,
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      services: { required },
    },
  },
  created() {
    this.fetchSingleHospital(this.$route.params.id);
  },
};
</script>
